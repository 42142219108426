import "../scss/main.scss";

import manage_summary_buttons from "./summary.js";
import showing_problems_on_buy_forms from "./buy.js";
import manage_documents_form from "./documents.js";
import showing_faq_answers from "./faq.js";
import manage_form from "./form.js";
import manage_survey_form from "./survey.js";
import navbar_toggling from "./navbar.js";
import scrolling_anchors from "./scrolling.js";
import readiness_gauge_meter from "./readiness.js";
import add_form_popups from "./form_popups.js";
import countdown from "./countdown.js";
import manage_cookie_notice from "./cookie_notice.js";
import show_contract_info from "./show_contract_info.js";
import show_surname_field_mechanism from "./form_data_controller.js";
import handle_investors_fields from "./investors_form.js";
import handle_attachment_fields from "./attachment_form.js";
import togglePopupWindow from "./toggle_popup_window.js";
import { tooltipMainMenu, tooltipDashboardList } from "./tooltips.js";
import addTogglePasswordVisibility from "./password_show.js";
import { validateRegistrationForm } from "./registration.js";
import validateAccountDetailsForm from "./profile_edit.js";
import validatePasswordReset from "./password_reset.js";
import validatePasswordChange from "./password_change.js";
import loginInvite from "./login_invite.js";
import autosize from "./autosize.js";

import manageMultipleDynamicInputs from "./add_form_item.js";
import handleFormsetMaxFieldCount from "./management_form.js";
import { addCountFormsToDeleteListener } from "./count_fields_to_remove.js";
import switchContrast from "./contrast-switch.js";
import {
  formSelectInputHandler,
  initializeSelectInput,
} from "./form_select_input_handler.js";
import handleFileInputs from "./handle_file_inputs.js";
import handleInvestorsCopyField from "./handle_investors_copy.js";
import { initializeDeleteButtonsFunction } from "./delete_form.js";
import interactiveMap from "./interactive_map.js";
import initLotAutocomplete from "./lot_autocomplete.js";
import Dropzone from "dropzone";



function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

function initRepAccordions() {
  var ig = 1;
  $('.homepage.popups .contract-info-popup .markdown-text, .report-info .markdown-text').each(function () {
    var icnt = 0;
    $(this).children('p,ul,ol').each(function () {
      var ishead = false;
      var $s = $(this).find('strong');
      if ($s.length) {
        var _arr = $s.get(0).textContent.split('.');
        if ((_arr.length > 1) && (isNumeric(_arr[0]))) {
          ishead = true;          
        }
      }
      if (ishead) {
        icnt = icnt + 1;
        $(this).addClass('achead');
        $(this).addClass('achead-' + ig + '-' + icnt);
      } else {
        $(this).addClass('accont');
        $(this).addClass('accont-' + ig + '-' + icnt);
      }
    });
    for (var i = 1; i <= icnt; i++) {
      $('.achead-' + ig + '-' + i).wrap('<a href="javascript:;" class="actogg" id="actogg-' + ig + '-' + i + '"></a>');
      $('.accont-' + ig + '-' + i).wrap('<div class="acwrap acwrap-' + ig + '-' + i + '" style="display: none"></div>');
    }
    ig = ig + 1;
  });
  $('.actogg').click(function () {
    var cl = this.id.replace('actogg', 'acwrap');
    $('.' + cl).slideToggle();
  });
}


function initBuildingCondsStuff() {
  $('#characteristic-form #id_terrain_method').change(function () {
    if ($(this).val().includes('część działki')) {
      $('#characteristic-form #id_surface_area').closest('.row').show();
    } else {
      $('#characteristic-form #id_surface_area').val('');
      $('#characteristic-form #id_surface_area').closest('.row').hide();
    }
  }).change();
}



var __$wtree_inp = null;
var __wsearch_to = false;


function _buildWorksTexts() {
  var selected = $("#jstree").jstree("get_selected");
  var instance = $("#jstree").jstree(true);
  var ids = [];
  for (var i in selected) {
    ids.push(selected[i]);
  }
  ids.sort();
  var texts = {};
  var works_json = {};
  for (var i in ids) {
    const id = ids[i];
    var arr = ids[i].split('_');
    if (arr.length == 2) {
      var text = instance.get_node(arr[0]).text.split(' <span').shift();
      var textp = instance.get_node(id).text.split(' <span').shift();
      if (!(arr[0] in texts)) {
        texts[arr[0]] = '- ' + text;
      }
      texts[arr[0]] += (texts[arr[0]] == '- ' + text) ? ': ' : ', ';
      texts[arr[0]] += textp;
      if ($('#wt_' + id).length) {
        texts[arr[0]] += ' (liczba obiektów: ' + $('#wt_' + id).val() + ')';
      }
    } else {
      var textp = instance.get_node(id).text.split(' <span').shift();
      texts[id] = '- ' + textp;      
      if ($('#wt_' + id).length) {
        if ((instance.get_node(id + '_1') == null) || $('#wt_' + id).val() > 1) {
          texts[id] += ' (liczba obiektów: ' + $('#wt_' + id).val() + ')';
        }
      }
    }    
    if ($('#wt_' + id).length)
      works_json[id] = $('#wt_' + id).val();
    else
      works_json[id] = 1;
  }
  var _texts = [];
  for (var id in texts) {
    _texts.push(texts[id]);
  }
  var info = $('#works_info').val().trim();
  if (info.length) {
    _texts.push('');
    _texts.push(info);
  }
  __$wtree_inp.val(_texts.join('\n'));
  $('#works_json').val(JSON.stringify(works_json));
  autosize.update(__$wtree_inp);
  if (__$wtree_inp.val().includes('tymczasow')) {
    $('.show-for-temporary').show();
  } else {
    $('.show-for-temporary').hide();
  }
}


function _buildWorksCounts() {
    $('.wt-count').remove();
    const wjson = JSON.parse($('#works_json').val().replaceAll("'", '"'));
    var selected = $("#jstree").jstree("get_selected");
    for (var i in selected) {
      const id = selected[i];
      console.dir(id);
      const cnt = (id in wjson) ? wjson[id] : 1;
      $('#' + id + ' .jstree-anchor').first().append('<span class="wt-count"> (liczba obiektów: <input id="wt_' + id + '" type="number" min="1" max="100" step="1" value="' + cnt + '" style="width: 3em; height: 1.4em" onkeypress="if (!/[0-9\/]+/.test(event.key)) { event.preventDefault(); }" onclick="event.stopPropagation();" />)</span>');
      $('#wt_' + id).change(_buildWorksTexts);
    }
}


function initWorksModal() {
  autosize($('.works-tree textarea')); 
  if ($('.works-tree textarea').val().includes('tymczas')) {
    $('.show-for-temporary').show();
  } else {
    $('.show-for-temporary').hide();
  }
  $('.works-tree textarea').attr('readonly', 'readonly').css('background-color', '#FFF').click(function () {
    __$wtree_inp = $(this);        
    $('#works-info').val($('#works_info').val());
    try {
      $('#jstree').off('loaded.jstree');
      $('#jstree').off('click', '.jstree-anchor');
      $('#jstree').off("open_node.jstree");
      $('#jstree').off("close_node.jstree");
      $('#jstree').jstree("destroy");
    } catch (e) {}
    $('#jstree').jstree({
      "plugins" : [ "search", "checkbox" ],
      "checkbox" : {
        "three_state": false
      },
      "core" : {
        "themes": {
          "dots": false
        },
        'data': __works
      }}).on("changed.jstree", function (e, data) {
        _buildWorksTexts();
        _buildWorksCounts();        
      });
    $('#jstree').on('loaded.jstree', function () {
        const wjson = JSON.parse($('#works_json').val().replaceAll("'", '"'));
        for (var id in wjson) {
          $("#jstree").jstree('select_node', id);
          $("#wt_" + id).val(wjson[id]);
        }
      });
    $('#jstree').on('click', '.jstree-anchor', function (e) {
        $(this).jstree(true).toggle_node(e.target);
      });  
    $('#tree-search').keyup(function () {
        if (__wsearch_to) { clearTimeout(__wsearch_to); }
        __wsearch_to = setTimeout(function () {
          var v = $('#tree-search').val();
          if (v.length)
            $('#jstree').jstree(true).search(v);
          else
            $("#jstree").jstree("clear_search");          
        }, 250);      
      });
    $('#tree-search').change(function () {       
          var v = $('#tree-search').val();
          if (v.length == 0)
            $("#jstree").jstree("clear_search");          
      });         
    $('#worksModal').modal('show');        
  });
  $('#closeWorksModal').click(function () {
    $('#worksModal').modal('hide');
  });
  $('#works-info').change(function () {
    $('#works_info').val($('#works-info').val());
    _buildWorksTexts();
  });
}


$(function() {
  $("#promoInformation").modal("show");

  manage_form();
  manage_survey_form();
  manage_documents_form();
  manage_summary_buttons();
  showing_problems_on_buy_forms();
  showing_faq_answers();
  readiness_gauge_meter();
  add_form_popups();
  countdown();
  manage_cookie_notice();
  show_contract_info();
  show_surname_field_mechanism();
  handle_investors_fields();
  handle_attachment_fields();
  togglePopupWindow(
    "js-questionnaires-list-show-popup",
    "js-questionnaires-list-popup",
    "questionnaires-list-popup--hidden",
    "js-questionnaires-list-popup__close-popup",
  );
  togglePopupWindow(
    "js-login-button",
    "js-login-popup-overlay",
    "login-popup-overlay--hidden",
    "js-login-popup__close",
  );
  togglePopupWindow(
    "js-questionnaire_save_login-button",
    "js-questionnaire_save_login-popup-overlay",
    "login-popup-overlay--hidden",
    "js-questionnaire_save_login-popup__close",
  );
  tooltipDashboardList();
  tooltipMainMenu();
  switchContrast();
  addTogglePasswordVisibility();
  loginInvite();
  validateRegistrationForm();
  validatePasswordReset();
  validatePasswordChange();
  validateAccountDetailsForm();
  scrolling_anchors();
  navbar_toggling();

  const handleNewLotMapButton = interactiveMap();

  manageMultipleDynamicInputs({
    formsElementId: "attachments-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_attachments-TOTAL_FORMS",
    maxFormsInputElementId: "id_attachments-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el }) {
      const $clonedEl = $(el);
      $clonedEl.find(".form-item__file-input-button").show();
      $clonedEl.find(".form-item__file-name").text("");
      $clonedEl.find(".form-item__file-size").text("");
      initializeSelectInput(el);
    },
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_attachments-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_investors-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_investors_proxies-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_lots-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_roadlots-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_buildings-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_notbuildings-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_landfills-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_risks-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_spact_community_plans-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });  
  addCountFormsToDeleteListener({
    deleteFormElementsClassName: "js-form-field-delete",
    totalFormsInputElementId: "id_inspectors-TOTAL_FORMS",
    formItemsClassName: "form-item",
  });  
  manageMultipleDynamicInputs({
    formsElementId: "investors-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_investors-TOTAL_FORMS",
    maxFormsInputElementId: "id_investors-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
  });
  manageMultipleDynamicInputs({
    formsElementId: "investors-proxies-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_investors_proxies-TOTAL_FORMS",
    maxFormsInputElementId: "id_investors_proxies-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-investor-proxies-post-list-${elIndex - 1}`)
        .attr("id", `js-investor-proxies-post-list-${elIndex}`);
    },
  });
  manageMultipleDynamicInputs({
    formsElementId: "investors-representatives-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_investors_representatives-TOTAL_FORMS",
    maxFormsInputElementId: "id_investors_representatives-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-investor-proxies-post-list-${elIndex - 1}`)
        .attr("id", `js-investor-proxies-post-list-${elIndex}`);
    },
  });
  manageMultipleDynamicInputs({
    formsElementId: "lots-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_lots-TOTAL_FORMS",
    maxFormsInputElementId: "id_lots-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    handleNewLotMapButton,
  });
  manageMultipleDynamicInputs({
    formsElementId: "roadlots-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_roadlots-TOTAL_FORMS",
    maxFormsInputElementId: "id_roadlots-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    handleNewLotMapButton,
  });  
  manageMultipleDynamicInputs({
    formsElementId: "buildings-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_buildings-TOTAL_FORMS",
    maxFormsInputElementId: "id_buildings-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-buildings-post-list-${elIndex - 1}`)
        .attr("id", `js-buildings-post-list-${elIndex}`);
    },
  });
  manageMultipleDynamicInputs({
    formsElementId: "notbuildings-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_notbuildings-TOTAL_FORMS",
    maxFormsInputElementId: "id_notbuildings-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-notbuildings-post-list-${elIndex - 1}`)
        .attr("id", `js-notbuildings-post-list-${elIndex}`);
    },
  });
  manageMultipleDynamicInputs({
    formsElementId: "landfills-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_landfills-TOTAL_FORMS",
    maxFormsInputElementId: "id_landfills-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-landfills-post-list-${elIndex - 1}`)
        .attr("id", `js-landfills-post-list-${elIndex}`);
    },
  });
  manageMultipleDynamicInputs({
    formsElementId: "spactcomm-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_spact_community_plans-TOTAL_FORMS",
    maxFormsInputElementId: "id_spact_community_plans-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-spactcomm-post-list-${elIndex - 1}`)
        .attr("id", `js-spactcomm-post-list-${elIndex}`);
    },
    handleNewLotMapButton
  }); 
  manageMultipleDynamicInputs({
    formsElementId: "spactlocal-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_spact_local_plans-TOTAL_FORMS",
    maxFormsInputElementId: "id_spact_local_plans-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-spactlocal-post-list-${elIndex - 1}`)
        .attr("id", `js-spactlocal-post-list-${elIndex}`);
    },
    handleNewLotMapButton
  });  
  manageMultipleDynamicInputs({
    formsElementId: "spactother-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_spact_other_plans-TOTAL_FORMS",
    maxFormsInputElementId: "id_spact_other_plans-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-spactother-post-list-${elIndex - 1}`)
        .attr("id", `js-spactother-post-list-${elIndex}`);
    },
    handleNewLotMapButton
  });  
  manageMultipleDynamicInputs({
    formsElementId: "risks-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_risks-TOTAL_FORMS",
    maxFormsInputElementId: "id_risks-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-risks-post-list-${elIndex - 1}`)
        .attr("id", `js-risks-post-list-${elIndex}`);
    },
  });  
  manageMultipleDynamicInputs({
    formsElementId: "inspectors-form-items",
    addFormButtonId: "add-form-item",
    formItemClassName: "form-item",
    totalFormsInputElementId: "id_inspectors-TOTAL_FORMS",
    maxFormsInputElementId: "id_inspectors-MAX_NUM_FORMS",
    dontZeroValueClassName: "js-form-field-id",
    deleteFormElementSelector: ".js-form-field-delete",
    deleteFormElementsClassName: "js-form-field-delete",
    afterClone({ el, elIndex }) {
      const $clonedEl = $(el);
      $clonedEl
        .find(`#js-inspectors-post-list-${elIndex - 1}`)
        .attr("id", `js-inspectors-post-list-${elIndex}`);
    },
  }); 
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_attachments-TOTAL_FORMS",
    maxFormsInputElementId: "id_attachments-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_investors-TOTAL_FORMS",
    maxFormsInputElementId: "id_investors-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_investors_proxies-TOTAL_FORMS",
    maxFormsInputElementId: "id_investors_proxies-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_lots-TOTAL_FORMS",
    maxFormsInputElementId: "id_lots-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_roadlots-TOTAL_FORMS",
    maxFormsInputElementId: "id_roadlots-MAX_NUM_FORMS",
  });  
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_buildings-TOTAL_FORMS",
    maxFormsInputElementId: "id_buildings-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_notbuildings-TOTAL_FORMS",
    maxFormsInputElementId: "id_notbuildings-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_landfills-TOTAL_FORMS",
    maxFormsInputElementId: "id_landfills-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_risks-TOTAL_FORMS",
    maxFormsInputElementId: "id_risks-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_spact_community_plans-TOTAL_FORMS",
    maxFormsInputElementId: "id_spact_community_plans-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_spact_local_plans-TOTAL_FORMS",
    maxFormsInputElementId: "id_spact_local_plans-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_spact_other_plans-TOTAL_FORMS",
    maxFormsInputElementId: "id_spact_other_plans-MAX_NUM_FORMS",
  });
  handleFormsetMaxFieldCount({
    saveButtonId: "save-button",
    totalFormsInputElementId: "id_inspectors-TOTAL_FORMS",
    maxFormsInputElementId: "id_inspectors-MAX_NUM_FORMS",
  });
  formSelectInputHandler();
  handleFileInputs();
  handleInvestorsCopyField();
  initializeDeleteButtonsFunction();
  $('[data-toggle="tooltip"]').tooltip();
  $("#carousel-testimonials").carousel({
    interval: 10000,
  });

  $("#carousel-hero").carousel({
    interval: false,
  });

  $(".landing__card").click(function() {
    $(this)
      .find(".hidden-text")
      .toggleClass("hidden-text--show");
  });

  initLotAutocomplete();

  var $fileInput = $('.file-input');
  var $droparea = $('.file-drop-area');

  $fileInput.on('dragenter focus click', function() {
    $droparea.addClass('is-active');
  });


  $fileInput.on('dragleave blur drop', function() {
    $droparea.removeClass('is-active');
  });

  $fileInput.on('change', function() {    
    var filesCount = $(this)[0].files.length;
    var $textContainer = $(this).prev();

    if (filesCount === 1) {      
      var fileName = $(this).val().split('\\').pop();
      $textContainer.text(fileName);
    } else {
      $textContainer.text('Ilość plików: ' + filesCount);
    }

    var formId = $fileInput.data('formid');
    $('#' + formId).submit();
  });

  $('#sharing-select').change(function() {
    const val = $(this).val();
    const url = new URL(window.location.href);
    var saved = url.searchParams.get("saved");
    var newUrl = document.location.protocol +"//"+ document.location.hostname + (window.location.port ? ':' + window.location.port : '') + document.location.pathname;
    if (val == 'only-my') {
        newUrl += '?shared=hide';
        if (saved) newUrl += '&saved=' + saved;
    }
    else if (val == 'only-shared') {
        newUrl += '?shared=only'
        if (saved) newUrl += '&saved=' + saved;
    }
    else {
        if (saved) newUrl += '?saved=' + saved;
    }
    document.location.href = newUrl;
  });

  initRepAccordions();

  initBuildingCondsStuff();
  
  initWorksModal();

  $.notify.defaults({ globalPosition: 'top right' });
});
